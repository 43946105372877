<template>
  <v-app>
    <v-app-bar app color="board darken-4" dark>
      <div class="d-flex align-center"></div>
    </v-app-bar>
    <v-main>
      <Home />
    </v-main>
    <v-footer app elevation="3" absolute>
      <v-row class="mt-0 mb-0">
        <v-col cols="12" class="text-right pt-0 pb-0 mt-0 mb-0">
          <v-flex align-end>
            <a
              href="https://infovita.net?utm_source=holamanola.es&utm_campaign=footer"
            >
              <img
                src="/img/infovita_foot_grey.svg"
                height="40px"
                class="pt-0 pb-0 mt-0 mb-0"
              />
            </a>
          </v-flex>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>
<script>
import Home from "./components/Home";
export default {
  metaInfo: {
    title: "Hola Manola Café & Bar",
    meta: [
      { name: "description", content: "Café Bar en Castellón de la Plana" },
      {
        keywords: "cafe, bar, cosas ricas, postres, tortas, comida, cafetería",
      },
    ],
    htmlAttrs: {
      lang: "es",
      amp: true,
    },
  },
  components: {
    Home,
  },
};
</script>
