<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          src="/img/logo.svg"
          lazy-src="/img/logo.svg"
          class="my-3"
          contain
          height="200"
        />
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col cols="12" md="2"></v-col>
      <v-col cols="12" md="8">
        <v-carousel cycle height="500">
          <v-carousel-item
            v-for="(item, i) in items"
            :key="i"
            :src="item.src"
            reverse-transition="fade-transition"
            transition="fade-transition"
            hide-delimiters
          ></v-carousel-item>
        </v-carousel>
      </v-col>
      <v-col cols="12" md="2"></v-col>
    </v-row>
    <v-row class="text-center">
      <v-col class="mb-5" cols="12">
        <h2 class="headline font-weight-bold mb-3">Sitio en Construcción</h2>
        <v-row justify="center">
          <a
            href="mailto:info@holamanola.es"
            class="subheading mx-3"
            target="_blank"
          >
            info@holamanola.es
          </a>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  metaInfo: {
    titleTemplate: "%s - Home",
  },
  data: () => ({
    items: [
      { src: "/img/food/a12.jpg" },
      { src: "/img/food/b42.jpg" },
      { src: "/img/food/c34.jpg" },
      { src: "/img/food/a32.jpg" },
      { src: "/img/food/v23.jpg" },
      { src: "/img/food/a32.jpg" },
    ],
  }),
};
</script>
